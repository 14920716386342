import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { ThemeProvider, SEO } from "@components"
import { GlobalStyle } from "@styles"

import { BrowserView, MobileView } from 'react-device-detect';
import { Lenis as ReactLenis } from '@studio-freight/react-lenis'

const Layout = ({ children, location }) => {
  return (
    <StaticQuery
      query={graphql`
        query SeoQuery {
          site {
            siteMetadata {
              title
              description
              image
            }
          }
        }
      `}
      render={site => (
        <>
          <SEO metadata={site.site.siteMetadata} />
          <ThemeProvider>
            <GlobalStyle />
            <BrowserView>
              <ReactLenis root>
                {children}
              </ReactLenis>
            </BrowserView>
            {/* Don't use lenis on mobile */}
            <MobileView>
              {children}
            </MobileView>
          </ThemeProvider>
        </>
      )}
    />
  );
};

export default Layout

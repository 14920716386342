import React from 'react'
import styled from 'styled-components'

import { media } from "@styles"

const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props => props.alignRight ? 'flex-end' : 'flex-start'};
  margin-top: 3rem;
`

const ContentWrapper = styled.div`
  display: flex;
  padding: 0 3rem 0 1.4rem;
`

const SectionNumber = styled.h1`
  margin: 0;
  margin-right: 3rem;
  font-size: 2rem;
  color: var(--accent);
  font-family: 'manrope-regular', sans-serif;
  ${media.giant`font-size: 1.6rem;`}
`

const SectionTitle = styled.h1`
  margin: 0;  
  font-size: 2rem;
  color: var(--text);
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
  ${media.giant`font-size: 1.6rem;`}
`

const SectionHeader = ({ number, title, alignRight }) => {

  return (
    <SectionWrapper alignRight={alignRight}>
      <ContentWrapper>
        <SectionNumber>/{number}</SectionNumber>
        <SectionTitle>{title}</SectionTitle>
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default SectionHeader;
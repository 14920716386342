import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { media } from "@styles"
import { Section, SectionHeader } from '@components';

const TextWrapper = styled.div`
  padding: 2rem 1.4rem 4rem 1.4rem;
  width: 700px;
  ${media.tablet`width: 500px;`};
  ${media.phone`width: 90%;`};
`

const Text = styled.span`
  display: block;
  margin: 0.5rem 0;
  overflow: hidden;
  text-decoration: none;
  font-size: 1.6rem;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
  color: var(--text);
  ${media.giant`font-size: 1.3rem;`}
`

const Spacer = styled.div`
  height: ${props => props.height || 0};
`

const Intro = () => {
  const [animateBorder, setAnimateBorder] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {
      setAnimateBorder(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <Section className={animateBorder ? 'animate-section' : ''}>
      <SectionHeader number={'00'} title={''} />

      <TextWrapper>
        <Text>
          <Fade bottom duration={800}>
            Hi, I'm Raunaq.
          </Fade>
        </Text>

        <Spacer height={"1rem"} />

        <Text>
          <Fade bottom duration={800} delay={500}>
            I'm a student at the University of Pennsylvania pursuing my bachelor's and master's
            in Computer Science with a minor in Mathematics.
          </Fade>
        </Text>

        <Text>
          <Fade bottom duration={800} delay={750}>
            Particularly passionate about complex, distributed systems that can scale to meet
            the demands of the modern world. And C++.
          </Fade>
        </Text>

        <Text>
          <Fade bottom duration={800} delay={1000}>
            Focused on writing beautiful, maintainable, and high-performance code.
          </Fade>
        </Text>

        <Spacer height={"1rem"} />

        <Text>
          <Fade bottom duration={800} delay={1500}>
            <>
              Currently at <a href="https://fiverings.com/">Five Rings</a>.
              Previously at <a href="https://zoox.com/">Zoox</a>, (an Amazon company).
            </>
          </Fade>
        </Text>
      </TextWrapper>
    </Section >
  );
};

export default Intro;

import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { media } from "@styles"

const TopBar = styled.div`
  position: fixed;
  top: calc(100% - 124px);
  right: 24px;
  height: ${props => props.inputHeight || '0px'};
  width: 2px;
  z-index: 500;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  mix-blend-mode: difference;
  ${media.phone`display: none;`};
`

const BottomBar = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
  height: ${props => props.inputHeight || '0px'};
  width: 2px;
  z-index: 500;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  mix-blend-mode: difference;
  ${media.phone`display: none;`};
`

const ScrollIndicator = () => {

  const [topHeight, setTopHeight] = useState('0px');
  const [bottomHeight, setBottomHeight] = useState('75px');

  const onScroll = (ev) => {
    hideOrDisplayScrollIndicator();

    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var scrolled = (winScroll / height);

    // Note: leaving buffer of 2px due to smooth scroll lerp
    setTopHeight((scrolled * 75 - 2).toString() + 'px');
    setBottomHeight((73 - scrolled * 75).toString() + 'px');
  }

  const hideOrDisplayScrollIndicator = () => {
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;

    if (winScroll > 0.8 * window.innerHeight) {
      document.getElementById("topbar").style.opacity = 1;
      document.getElementById("bottombar").style.opacity = 1;
    } else {
      document.getElementById("topbar").style.opacity = 0;
      document.getElementById("bottombar").style.opacity = 0;
    }
  };

  useEffect(() => {
    if (document.readyState === 'complete') {
      hideOrDisplayScrollIndicator();
    } else {
      window.addEventListener('load', hideOrDisplayScrollIndicator);
    }

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener('load', hideOrDisplayScrollIndicator);
      window.removeEventListener("scroll", onScroll);
    }
  }, [])

  // useEffect(() => {
  //   window.addEventListener("scroll", onScroll);
  //   return () => window.removeEventListener("scroll", onScroll);
  // }, []);

  return (
    <>
      <TopBar id="topbar" inputHeight={topHeight} />
      <BottomBar id="bottombar" inputHeight={bottomHeight} />
    </>
  )
}

export default ScrollIndicator

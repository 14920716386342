import React from 'react'
import styled from "styled-components"

import lightModeImg from '@static/lightmode.png';
import darkModeImg from '@static/darkmode.png';

const LightMode = styled.div`
  background-image: url(${darkModeImg});
  background-repeat: no-repeat;
  background-size: cover;
`

const DarkMode = styled.div`
  background-image: url(${lightModeImg});
  background-repeat: no-repeat;
  background-size: cover;
`

const Toggle = ({ mode }) => {
  switch (mode) {
    case 'lightmode':
      return <LightMode />;
    case 'darkmode':
      return <DarkMode />;
    default:
      return <DarkMode />;
  }
};

export default Toggle;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Section } from '@components';
import { media } from "@styles"
import { useLenis } from '@studio-freight/react-lenis'

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 1.7rem 3.2rem 1.4rem 1.4rem;
  bottom: 0;
  ${media.phone`padding-right: 1.4rem`};
`

const FooterText = styled.span`
  text-align: center;
  text-decoration: none;
  font-size: 1.2rem;
  color: var(--text);
  font-family: 'manrope-light', sans-serif;
  ${media.giant`font-size: 0.8rem;`}
`

const Blinker = styled.span`
  transition: opacity 0.5s;
`

const SVGFill = styled.g`
  fill: var(--text);
  fill-opacity: 0;
  transition: fill-opacity 0.3s;
`

const ScrollButton = styled.div`
  margin-bottom: 10px;
  position: relative;
  top: 0;
  transition: top ease 0.3s;
`

const ScrollButtonWrapper = styled.button`
  background: none;
  padding-top: 10px;
  cursor: pointer;
  border: none;
  &:hover ${ScrollButton} {
    top: -10px;
  }
  &:hover ${SVGFill} {
    fill-opacity: 1;
  }
`

const Footer = () => {
  const lenis = useLenis(({ scroll }) => { })
  const [showBlinker, setShowBlinker] = useState(true);
  const [animateBorder, setAnimateBorder] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {
      setAnimateBorder(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
    }

    const interval = setInterval(() => {
      setShowBlinker(showBlinker => !showBlinker);
      const time = new Date().toLocaleTimeString('en-US', {
        timeZone: 'America/New_York', weekday: 'long', month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hourCycle: 'h23'
      }).toString().replaceAll(',', '').split(/:| /);
      const dateString = time.slice(0, 4).join(' ').toLowerCase();
      document.querySelector('#date').innerText = dateString;

      document.querySelector('#hours').innerText = time[time.length - 3];
      document.querySelector('#minutes').innerText = time[time.length - 2];
      document.querySelector('#seconds').innerText = time[time.length - 1];
    }, 1000);

    return () => {
      window.removeEventListener('load', onPageLoad);
      clearInterval(interval);
    }
  }, []);

  const scrollToTop = () => {
    lenis.scrollTo(0);
  }

  return (
    <Section className={animateBorder ? 'animate-section' : ''} style={{ zIndex: 100 }}>
      <FooterWrapper>
        <FooterText>raunaq singh.</FooterText>
        <div style={{ display: 'flex', flexDirection: 'column', justifyItems: 'right', whiteSpace: 'pre', alignItems: 'end' }}>
          <ScrollButtonWrapper onClick={scrollToTop}>
            <ScrollButton onClick={scrollToTop}>
              <svg width="40" height="46" viewBox="0 0 440 506" xmlns="http://www.w3.org/2000/svg">
                <SVGFill fill="none">
                  <path d="M264 144.63V502H175V144.687V134.31L168.034 142.003L71.6266 248.487L5.64989 188.754L172.92 4H266.123L433.406 188.747L367.433 248.484L270.965 141.945L264 134.253V144.63Z" stroke="var(--text)" strokeWidth="8" />
                </SVGFill>
              </svg>
            </ScrollButton>
          </ScrollButtonWrapper>

          <FooterText>39.95210˚N, 75.19308˚W</FooterText>
          <FooterText>
            <span id="date" style={{ marginRight: 10 }}></span>
            <span id="hours"></span>
            <Blinker style={{ opacity: showBlinker ? 1 : 0 }}>:</Blinker>
            <span id="minutes"></span>
            <Blinker style={{ opacity: showBlinker ? 1 : 0 }}>:</Blinker>
            <span id="seconds"></span>
          </FooterText>
        </div>
      </FooterWrapper>
    </Section>
  );
};

export default Footer;

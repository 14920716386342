import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import '@styles/header.css';

import Slide from 'react-reveal/Slide';

const HeaderWrapper = styled.div`
  font-size: 0;
  margin-top: 5vh;
  @media screen and (orientation:portrait) {
    direction: rtl;
    margin-top: 5vh;
    padding: 0 5vh 0 5vh;
    border-top: 1px solid var(--text);
  }
`

const LeftLine = styled.div`
  overflow: hidden;
  position: absolute;
  margin: 0;
  padding: 0;
  color: var(--text);
  box-sizing: border-box;
  width: 1px;
  @media screen and (orientation:portrait) {
    display: inline-block;
    height: 90vh;
  };
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform 0.75s ease-in-out;
    @media screen and (orientation:portrait) {
      border-left: 1px solid var(--text);
      transform: scaleY(0);
    }
  }
`

const HeaderText = styled.h1`
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 21vh;
  line-height: 22.5vh;
  height: 22.5vh;
  font-family: 'manrope-semibold', sans-serif;
  text-transform: uppercase;
  color: var(--text);
  box-sizing: border-box;
  @media screen and (orientation:portrait) {
    padding: 1.25rem 0;
    display: inline-block;
    width: 25%;
    vertical-align: top;
    writing-mode: vertical-rl;
    height: 90vh;
    direction: ltr;
    font-size: 17.5vw;
    line-height: 24vw;
  };
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid var(--text);
    transform: scaleX(0);
    transition: transform 0.75s ease-in-out;
    @media screen and (orientation:portrait) {
      border-top: none !important;
      border-right: 1px solid var(--text);
      transform: scaleY(0);
    }
  }
`

const Header = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [playAnimation, setPlayAnimation] = useState(false);
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
      const width = window.innerWidth || document.documentElement.clientWidth ||
        document.body.clientWidth;
      const height = window.innerHeight || document.documentElement.clientHeight ||
        document.body.clientHeight;
      setIsPortrait(height >= width);
      setIsLoading(false);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <HeaderWrapper>
          <HeaderText />
          <HeaderText />
          <HeaderText />
          <HeaderText />
          <LeftLine />
        </HeaderWrapper >
      ) : (
        <HeaderWrapper className={playAnimation ? 'animate-header left' : 'left'}>
          <HeaderText className={playAnimation ? 'animate-header left' : 'left'} style={{ color: 'var(--accent)' }}>
            <Slide left={isPortrait} bottom={!isPortrait} cascade delay={100}>
              Raunaq
            </Slide>
          </HeaderText >
          <HeaderText className={playAnimation ? 'animate-header right' : 'right'} style={{ color: 'var(--accent)' }}>
            <Slide left={isPortrait} bottom={!isPortrait} cascade delay={150}>
              Singh
            </Slide>
          </HeaderText >
          <HeaderText className={playAnimation ? 'animate-header left' : 'left'}>
            <Slide left={isPortrait} bottom={!isPortrait} cascade delay={200}>
              Creative
            </Slide>
          </HeaderText>
          <HeaderText className={playAnimation ? 'animate-header right' : 'right'}>
            <Slide left={isPortrait} bottom={!isPortrait} cascade delay={250}>
              Dev.
            </Slide>
          </HeaderText>
          <LeftLine className={playAnimation ? 'animate-header left' : 'left'} />
        </HeaderWrapper >
      )}
    </>
  );
};

export default Header;
import React, { useEffect } from "react"
import { navigate } from 'gatsby'
import styled from "styled-components"
import { Link } from "gatsby"
import { useLenis } from '@studio-freight/react-lenis'
import { motion, useViewportScroll, useTransform } from "framer-motion"

import { ThemeContext, Toggle } from "./"

const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  top: ${props => props.alwaysDisplay ? '0' : '-3.5rem'};
  transition: top 0.65s ease;
  background: var(--background);
  border-bottom: 1px solid var(--text);
`

const NavBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 1rem 1.4rem;
`

export const SectionLink = styled.text`
  text-align: center;
  text-decoration: none;
  font-size: 1.2rem;
  line-height 1.2rem;
  color: var(--text);
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
`

export const SVGFill = styled.g`
  fill: white;
`

export const ThemeWrapper = styled(Link)`
  display: block;
  right: 0;
  margin-left: auto;
  margin-right: 0;
  z-index: 999;
  label {
    cursor: pointer;
    input {
      display: none;
    }
  }
  div {
    width: 1rem;
    height: 1rem;
  }
`

const NavBar = ({ alwaysDisplay }) => {
  const lenis = useLenis(({ scroll }) => { })
  const { colorMode, setColorMode } = React.useContext(ThemeContext)

  const { scrollYProgress } = useViewportScroll()
  const rotation = useTransform(scrollYProgress, [0, 1], [0, 180])

  const scrollToTop = () => {
    lenis.scrollTo(0);
    navigate("/");
  }

  const hideOrDisplayNavBar = () => {
    const winScroll = document.body.scrollTop ||
      document.documentElement.scrollTop;

    if (winScroll > 0.8 * window.innerHeight || alwaysDisplay) {
      document.getElementById("navbar").style.top = "0";
    } else {
      document.getElementById("navbar").style.top = "-3.5rem";
    }
  };

  useEffect(() => {
    if (document.readyState === 'complete') {
      hideOrDisplayNavBar();
    } else {
      window.addEventListener('load', hideOrDisplayNavBar);
    }

    window.addEventListener("scroll", hideOrDisplayNavBar);

    return () => {
      window.removeEventListener('load', hideOrDisplayNavBar);
      window.removeEventListener("scroll", hideOrDisplayNavBar);
    }
  }, [])

  return (
    <Container id="navbar" alwaysDisplay={alwaysDisplay}>
      <NavBarWrapper>
        <SectionLink onClick={scrollToTop}>raunaq singh.</SectionLink>
        <ThemeWrapper>
          <motion.div
            style={{
              rotate: rotation,
            }}
          >
            <label>
              <input
                type="checkbox"
                onChange={ev => {
                  setColorMode(ev.target.checked ? "dark" : "light")
                }}
                checked={colorMode === "dark"}
              />
              {colorMode === "dark" ? (
                <Toggle mode="lightmode" />
              ) : (
                <Toggle mode="darkmode" />
              )}
            </label>
          </motion.div>
        </ThemeWrapper>
      </NavBarWrapper>
    </Container>
  )
}

export default NavBar

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import gsap from 'gsap';
import Fade from 'react-reveal/Fade';

import { map, lerp, clamp, getMousePos } from './utils';
import { Section, SectionHeader } from '@components';
import { media } from "@styles"

import sampleImg from '@static/sample.jpg';
import sampleImg2 from '@static/sample2.jpg';

const TitleWrapper = styled.div`
  padding: 2rem 1.4rem 0 1.4rem;
`

const AccentTitle = styled.h1`
  display: flex;
  overflow: hidden;
  font-size: 3.3rem;
  margin: 0;
  width: 100%;
  font-family: 'manrope-regular', sans-serif;
  color: var(--accent);
  ${media.giant`font-size: 2.3rem;`}
`

const SolidTitle = styled.h1`
  display: flex;
  overflow: hidden;
  font-size: 3.3rem;
  line-height: 3.3rem;
  margin: 0;
  margin-bottom: 4rem;
  color: var(--text);
  width: 100%; 
  overflow: hidden;
  font-family: 'manrope-light', sans-serif;
  ${media.giant`font-size: 2.3rem; line-height: 2.3rem; margin-bottom: 3rem;`}
`

const ContactText = styled.span`
  position: relative;
  display: block;
  font-size: 4.3rem;
  padding-left: 1.4rem;
  overflow: hidden;
  white-space: nowrap;
  color: var(--text);
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
  ${media.giant`font-size: 3.3rem;`}
`

const ContactLink = styled.a`
  text-decoration: none;
  flex: none;
  display: flex;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    border-right: 12px solid var(--text);
    top: 20%;
    height: 65%;
    opacity: 0;
    transform: translateX(1rem);
    transition: transform 0.3s, opacity 0.3s;
  }
  &:hover {
    &:after {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

const HoverReveal = styled.div`
  position: absolute;
  z-index: -1;
  width: min(30vw, 230px);
  height: min(42vw, 330px);
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
`

const HoverRevealInner = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
`

const HoverRevealImg = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  z-index: 1;
  display: none; // TODO: remove once pictures are ready!
`

let mousepos = { x: 0, y: 0 };
let mousePosCache = mousepos;
let direction = { x: mousePosCache.x - mousepos.x, y: mousePosCache.y - mousepos.y };

class ContactItem extends React.Component {
    // adapted from https://tympanus.net/codrops/2020/07/01/creating-a-menu-image-animation-on-hover/

    constructor(props) {
        super(props);

        this.animatableProperties = props.animatableProperties;
        this.image = props.image

        this.mouseEnter = this.mouseEnter.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);
        this.showImage = this.showImage.bind(this);
        this.hideImage = this.hideImage.bind(this);

        this.linkRef = React.createRef();
        this.revealRef = React.createRef();
        this.revealInnerRef = React.createRef();
        this.revealImgRef = React.createRef();
    }

    componentDidMount() {
        this.revealImgRef.current.style.backgroundImage = `url(${this.image})`;
        window.addEventListener('mousemove', ev => mousepos = getMousePos(ev));
    }

    mouseEnter() {
        this.showImage();
        this.firstRAFCycle = true;
        // start the render loop animation (rAF)
        this.loopRender();
    }

    mouseLeave() {
        this.stopRendering();
        this.hideImage();
    }

    loopRender() {
        if (!this.requestId) {
            this.requestId = requestAnimationFrame(() => this.animate());
        }
    }

    stopRendering() {
        if (this.requestId) {
            window.cancelAnimationFrame(this.requestId);
            this.requestId = undefined;
        }
    }

    calcBounds() {
        this.bounds = {
            el: this.linkRef.current.getBoundingClientRect(),
            reveal: this.revealRef.current.getBoundingClientRect()
        };
    }

    showImage() {
        // kill any current tweens
        gsap.killTweensOf(this.revealInnerRef.current);
        gsap.killTweensOf(this.revealImgRef.current);

        this.tl = gsap.timeline({
            onStart: () => {
                // show the image element
                gsap.to(this.revealRef.current, 0.3, { opacity: 1 });
                // set a high z-index value so image appears on top of other elements
                gsap.set(this.linkRef.current, { zIndex: 5 });
            }
        })
            // animate the image wrap
            .to(this.revealInnerRef.current, 0.2, {
                ease: 'Sine.easeOut',
                startAt: { x: direction.x < 0 ? '-100%' : '100%' },
                x: '0%'
            })
            // animate the image element
            .to(this.revealImgRef.current, 0.2, {
                ease: 'Sine.easeOut',
                startAt: { x: direction.x < 0 ? '100%' : '-100%' },
                x: '0%'
            }, 0);
    }

    // hide the image element
    hideImage() {
        // kill any current tweens
        gsap.killTweensOf(this.revealInnerRef.current);
        gsap.killTweensOf(this.revealImgRef.current);

        this.tl = gsap.timeline({
            onStart: () => {
                gsap.set(this.linkRef.current, { zIndex: 1 });
            },
            onComplete: () => {
                gsap.to(this.revealRef.current, 0.1, { opacity: 0 });
                gsap.to(this.revealRef.current, 0, { delay: 0.1, pointerEvents: "none" });
            }
        }).to(this.revealInnerRef.current, 0.2, {
            ease: 'Sine.easeOut',
            x: direction.x < 0 ? '100%' : '-100%'
        }).to(this.revealImgRef.current, 0.2, {
            ease: 'Sine.easeOut',
            x: direction.x < 0 ? '-100%' : '100%'
        }, 0);
    }

    animate() {
        this.requestId = undefined;
        // calculate position/sizes the first time
        if (this.firstRAFCycle) {
            this.calcBounds();
        }

        // calculate the mouse distance (current vs previous cycle)
        const mouseDistanceX = clamp(Math.abs(mousePosCache.x - mousepos.x), 0, 100);
        // direction where the mouse is moving
        direction = { x: mousePosCache.x - mousepos.x, y: mousePosCache.y - mousepos.y };
        // updated cache values
        mousePosCache = { x: mousepos.x, y: mousepos.y };

        // new translation values
        // the center of the image element is positioned where the mouse is
        this.animatableProperties.tx.current = Math.abs(mousepos.x - this.bounds.el.left) - this.bounds.reveal.width / 2;
        this.animatableProperties.ty.current = Math.abs(mousepos.y - this.bounds.el.top) - this.bounds.reveal.height / 2;// + this.bounds.el.height/2 - this.bounds.reveal.height/2;//Math.abs(mousepos.y - this.bounds.el.top) - this.bounds.reveal.height/2;

        // new rotation value
        this.animatableProperties.rotation.current = this.firstRAFCycle ? 0 : map(mouseDistanceX, 0, 100, 0, direction.x < 0 ? 60 : -60);
        // new filter value
        // this.animatableProperties.brightness.current = this.firstRAFCycle ? 1 : map(mouseDistanceX, 0, 100, 1, 4);

        // set up the interpolated values
        // for the first cycle, both the interpolated values need to be the same so there's no "lerped" animation between the previous and current state
        this.animatableProperties.tx.previous = this.firstRAFCycle ? this.animatableProperties.tx.current : lerp(this.animatableProperties.tx.previous, this.animatableProperties.tx.current, this.animatableProperties.tx.amt);
        this.animatableProperties.ty.previous = this.firstRAFCycle ? this.animatableProperties.ty.current : lerp(this.animatableProperties.ty.previous, this.animatableProperties.ty.current, this.animatableProperties.ty.amt);
        this.animatableProperties.rotation.previous = this.firstRAFCycle ? this.animatableProperties.rotation.current : lerp(this.animatableProperties.rotation.previous, this.animatableProperties.rotation.current, this.animatableProperties.rotation.amt);
        // this.animatableProperties.brightness.previous = this.firstRAFCycle ? this.animatableProperties.brightness.current : lerp(this.animatableProperties.brightness.previous, this.animatableProperties.brightness.current, this.animatableProperties.brightness.amt);

        // set styles
        gsap.set(this.revealRef.current, {
            x: this.animatableProperties.tx.previous,
            y: this.animatableProperties.ty.previous,
            rotation: this.animatableProperties.rotation.previous,
            // filter: `brightness(${this.animatableProperties.brightness.previous})`
        });

        // loop
        this.firstRAFCycle = false;
        this.loopRender();
    }

    render() {
        return (
            <ContactLink ref={this.linkRef} href={this.props.url} target={"_blank"} spy={true} smooth={true} duration={5000} onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                <ContactText>
                    <Fade bottom delay={this.props.enterDelay}>
                        {this.props.title}
                    </Fade>
                </ContactText>
                <HoverReveal ref={this.revealRef}>
                    <HoverRevealInner ref={this.revealInnerRef}>
                        <HoverRevealImg ref={this.revealImgRef} />
                    </HoverRevealInner>
                </HoverReveal>
            </ContactLink>
        );
    }
}


const Contact = () => {
    const [animateBorder, setAnimateBorder] = useState(false);
    const animatableProperties = {
        tx: { previous: 0, current: 0, amt: 0.08 },
        ty: { previous: 0, current: 0, amt: 0.08 },
        rotation: { previous: 0, current: 0, amt: 0.08 },
        brightness: { previous: 1, current: 1, amt: 0.08 }
    };

    useEffect(() => {
        const onPageLoad = () => {
            setAnimateBorder(true);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <Section className={animateBorder ? 'animate-section' : ''} style={{ paddingBottom: "10rem" }}>
            <SectionHeader number={'03'} title={'contact.'} />
            <TitleWrapper>
                <AccentTitle>
                    <Fade bottom delay={0}>
                        don't be shy,
                    </Fade>
                </AccentTitle>
                <SolidTitle>
                    <Fade bottom delay={200}>
                        make the first move.
                    </Fade>
                </SolidTitle>
            </TitleWrapper>
            <ContactItem title={'email'} image={sampleImg} url={"mailto: helloraunaq@gmail.com"} enterDelay={400} animatableProperties={animatableProperties} />
            <ContactItem title={'github'} image={sampleImg2} url={"https://github.com/raunaqsingh2020"} enterDelay={600} animatableProperties={animatableProperties} />
            <ContactItem title={'linkedin'} image={sampleImg} url={"https://www.linkedin.com/in/raunaqsingh2020/"} enterDelay={800} animatableProperties={animatableProperties} />
            <ContactItem title={'instagram'} image={sampleImg2} url={"https://www.instagram.com/raunaq.singh70/"} enterDelay={1000} animatableProperties={animatableProperties} />
        </Section>
    );
};

export default Contact;

export const COLORS = {
  text: {
    light: '#131313',
    dark: '#FDFDFD',
  },
  accent: {
    light: '#D9D9D9',
    dark: '#A5A5A5',
  },
  background: {
    light: '#FDFDFD',
    dark: '#0A0A0A',
  },
  rgbShadow: {
    light: '19, 19, 19',
    dark: '45, 45, 45',
  }
};

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { media } from "@styles"
import { Section, SectionHeader } from '@components';

import Zoox from '@static/orgs/zoox';
import DailyPenn from '@static/orgs/dailypenn';
import PennLabs from '@static/orgs/pennlabs';
import Penn from '@static/orgs/penn';

const TextWrapper = styled.div`
  padding: 2rem 1.4rem 4rem 1.4rem;
  width: 700px;
  ${media.tablet`width: 500px;`};
  ${media.phone`width: 90%;`};
`

const SolidTitle = styled.h1`
  display: block;
  margin: 0;
  color: var(--text);
  overflow: hidden;
  text-transform: lowercase;
  font-size: 6rem;
  line-height: 6.2rem;
  font-family: 'manrope-light', sans-serif;
  ${media.giant`font-size: 4.6rem; line-height: 5rem;`}
`

const Text = styled.span`
  display: block;
  margin: 0.5rem 0;
  overflow: hidden;
  text-decoration: none;
  font-size: 1.6rem;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
  color: var(--text);
  ${media.giant`font-size: 1.3rem;`}
`

const Spacer = styled.div`
  height: ${props => props.height || 0};
`

const About = () => {
  const [animateBorder, setAnimateBorder] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {
      setAnimateBorder(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <Section className={animateBorder ? 'animate-section' : ''}>
      <SectionHeader number={'01'} title={'about.'} />

      <TextWrapper>
        <SolidTitle>
          <Fade bottom duration={800}>
            Music,
          </Fade>
        </SolidTitle>
        <SolidTitle>
          <Fade bottom duration={800} delay={200}>
            Sports,
          </Fade>
        </SolidTitle>
        <SolidTitle>
          <Fade bottom duration={800} delay={400}>
            Food.
          </Fade>
        </SolidTitle>

        <Spacer height={"1rem"} />

        <Text>
          <Fade bottom duration={800} delay={800}>
            When I'm not coding, I keep myself busy with a wide range of
            different hobbies. I enjoy listening to all sorts of music and
            discovering new artists on Spotify. I am also an avid Philly
            sports fan (go birds), and enjoy weightlifting and playing squash.
            And when I'm not doing any of that, you can find me in the kitchen
            cooking up a storm. Whether it's trying a new type of cuisine or
            signing up for an adrenaline-pumping activity, I'm always game for
            a new adventure and a chance to learn something new.
          </Fade>
        </Text>
      </TextWrapper>

      {/* <div style={{ padding: '30px', display: 'flex' }}>
        <div style={{ height: '5vw', width: 'fit-content', margin: '2.5vw' }}>
          <Zoox fill="var(--text)" />
        </div>
        <div style={{ height: '5vw', width: 'fit-content', margin: '2.5vw' }}>
          <PennLabs fill="var(--text)" />
        </div>
        <div style={{ height: '5vw', width: 'fit-content', margin: '2.5vw' }}>
          <DailyPenn fill="var(--text)" />
        </div>
        <div style={{ height: '5vw', width: 'fit-content', margin: '2.5vw' }}>
          <Penn fill="var(--text)" />
        </div>
      </div> */}
    </Section>
  );
};

export default About;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { media } from "@styles"
import { Section, SectionHeader } from '@components';

const TextWrapper = styled.div`
  padding: 1rem 1.4rem 4rem 1.4rem;
  width: 700px;
  ${media.tablet`width: 500px;`};
  ${media.phone`width: 90%;`};
`

const SolidTitle = styled.h1`
  display: block;
  margin: 0;
  color: var(--text);
  overflow: hidden;
  text-transform: lowercase;
  font-size: 3.3rem;
  line-height: 6rem;
  font-family: 'manrope-light', sans-serif;
  ${media.giant`font-size: 2.3rem; line-height: 5rem;`}
`

const Text = styled.span`
  display: block;
  margin: 0.5rem 0;
  overflow: hidden;
  text-decoration: none;
  font-size: 1.6rem;
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
  color: var(--text);
  ${media.giant`font-size: 1.3rem;`}
`

const Spacer = styled.div`
  height: ${props => props.height || 0};
`

const Projects = () => {
  const [animateBorder, setAnimateBorder] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {
      setAnimateBorder(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <Section className={animateBorder ? 'animate-section' : ''}>
      <SectionHeader number={'02'} title={'projects.'} />

      <TextWrapper>
        <SolidTitle>
          <Fade bottom duration={800}>
            Coming Soon.
          </Fade>
        </SolidTitle>
        {/* <SolidTitle>
          <Fade bottom duration={800} delay={200}>
            Sports,
          </Fade>
        </SolidTitle>
        <SolidTitle>
          <Fade bottom duration={800} delay={400}>
            Food.
          </Fade>
        </SolidTitle> */}

        {/* <Spacer height={"1rem"} /> */}

        <Text>
          <Fade bottom duration={800} delay={800}>
            Imagine some C++.
          </Fade>
        </Text>
      </TextWrapper>
    </Section>
  );
};

export default Projects;

import React from 'react'
import styled from 'styled-components'

const Section = styled.div`
  display: block;
  position: relative;
  width: 100vw;
  margin: 0;
  box-sizing: border-box;
  border-top: 1px solid transparent;
  height: fit-content;
  transition: border-top 1.5s;
  background: var(--background);
`

export default Section;